<template>
  <div class="sticker-insights-bar" :class="{ 'open': isStickerInsights, 'closed': !isStickerInsights }">
    <div class="d-flex py-3 fs-14 fw-bold">
      <div style="margin: auto 21px auto auto">
        <el-button link circle @click="closeStickerInsights()" size="small">
          <fai type="fal" icon="times" class="size-18 fw-bold"  />
        </el-button>
      </div>
    </div>
    <div class="c-insights">
      <section class="c-insights__section">
        <p class="c-insights__title">Sales</p>
        <h3 class="c-insights__price">${{ parseFloat(stickerInsights.totalRevenue).toFixed(2) }}</h3>
      </section>

    <div class="c-insights">
      <section class="c-insights__section">
        <p class="c-insights__title">Winners</p>
        <ul style="list-style: none; padding-left: 0;">
          <li v-for="winner in winners" :key="winner.id" class="winner-item" style="position: relative; display: flex; align-items: center; margin-bottom: 8px;">
            <img 
              v-if="winner.participantAvatar" 
              :src="winner.participantAvatar" 
              alt="Winner Image" 
              style="width: 20px; height: 20px; margin-right: 8px; border-radius: 50%;"
            />
            <fai 
              v-else 
              class="icon-size-14" 
              type="fas" 
              icon="user-circle" 
              style="width: 20px; height: 20px; margin-right: 8px;" 
            />
            <label :for="winner.id" style="c-add-sticker__winners-list">{{ winner.participantName }}</label>
              <p :id="winner.id" class="winner-date" :style="{ marginLeft: 'auto' }">
                <i>Notified on {{ formatDate(winner.dateUpdated) }}</i>
              </p>
          </li>
        </ul>
      </section>
    </div>
    <section class="c-insights__section c-insights__section--middle" v-if="stickerInsights && stickerInsights.causeSummary && stickerInsights.causeSummary.length">
        <p class="c-insights__title c-insights__title--part">Donations by cause</p>
        <article v-for="(cause, i) in stickerInsights.causeSummary" :key="i">
          <section>
            <p class="c-insights__subtitle">{{ cause.key }}</p>
            <article class="c-insights__progress">
              <section class="col">
                <el-progress
                  color="#1EBEAF"
                  :percentage="cause.value ? parseFloat((cause.value/stickerInsights.unitsSold) * 100).toFixed(2): 0"
                  :stroke-width="10"
                  :show-text="false"
                />
              </section>
              <span class="c-insights__sold">{{ cause.value }}</span>
            </article>
          </section>
        </article>
    </section>
<!--      <section class="c-insights__section">-->
<!--        <p class="c-insights__title c-insights__title&#45;&#45;part">Causes</p>-->
<!--        <article class="c-insights__cause" v-for="(cause, i) in stickerInsights.benevityCausesName" :key="i">-->
<!--          {{ cause }}&lt;!&ndash; {{ stickerInsights.benevityCausesName.length > 1 && i !== stickerInsights.benevityCausesName.length - 1 ? ', ' : '' }} &ndash;&gt;-->
<!--        </article>-->
<!--      </section>-->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

// Define the Winner interface
interface Winner {
  id: string;
  stickerId: string;
  entityId: string;
  participantName: string;
  participantAvatar: string;
  eligible: boolean;
  dateCreated: string;
  dateUpdated: string;
}

export default defineComponent({
  name: 'StickerInsights',
  components: { },
  computed: { },
  props: {
    isStickerInsights: { type: Boolean, default: false },
    stickerInsights: { type: Array, default: Object },
    winners: { type: Array as () => Winner[], default: () => [] },
    winnerName: { type: String, default: null },
  },
  emits: ['close-dialog'],
  methods: {
  formatDate(date: string): string {
    const options: Intl.DateTimeFormatOptions = { year: '2-digit' as '2-digit', month: 'numeric', day: 'numeric' };
    return new Date(date).toLocaleDateString(undefined, options);
  },
  },
  setup(props, { emit }) {
    async function closeStickerInsights() {
      emit('close-dialog');
    }

    return {
      closeStickerInsights,
    };
  },
});
</script>

<style lang="scss">
.sticker-insights-bar {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  font-family: "Red Hat Display";

  &.closed {
    width: 0;
  }

  &.open {
    width: 480px;
  }
}

.c-insights {
  display: flex;
  flex-direction: column;
  font-family: "Red Hat Display";

  &__title {
    color: black;
    font-size: 14px;
    line-height: 18px;
    font-weight: 900;
    margin: 0 0 10px;
  }

  &__cause, &__subtitle {
    font-weight: 500;
    font-size: 12px;
    color: black;
  }

  &__subtitle {
    margin: 13px 0 6px;
  }

  &__price {
    font-weight: 700;
    font-size: 30px;
    line-height: 54px;
  }

  &__sold {
    font-size: 12px;
    font-weight: 500;
  }

  &__progress {
    display: flex;
    gap: 17px;
    align-items: center;
  }

  &__section {
    padding: 0 30px;
    &--middle {
      padding: 25px 30px;
      margin: 25px 0;
      border-top: 1px solid #EFEFEF;
    }
  }

  .winner-date {
  margin-top: 0;
  margin-bottom: 0;
  color: #999999;
}
}
</style>
