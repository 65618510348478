<script type="ts" setup>
  import { ref, computed, toRaw } from 'vue';
  import { useStore, mapGetters } from 'vuex';
  import {useRoute} from "vue-router";

  import jsPDF from 'jspdf';
  import 'jspdf-autotable';

  import { ElLink, ElText } from 'element-plus'
  import AppMainContent from "@/components/AppMainContent.vue";
  import ActivityTable from "@/views/activity/ActivityTable.vue";
  import ActivityTableFilters from "@/views/activity/ActivityTableFilters.vue";
  import SelectOrganizationWarning from "@/components/SelectOrganizationWarning.vue";

  const store = useStore();
  const isOrgAdmin = store.getters['users/isOrgAdmin'];
  const route = useRoute();

  const showSelectOrganization = ref(false);

  if (isOrgAdmin) {
    if (!route.params.id || route.params.id === ':id' || route.params.id === 'ORG_ID' || route.params.id === 'undefined')  {
      showSelectOrganization.value = true;
    }
  }

  const selectedRows = computed(() => {
    return store.getters["activityLogs/GET_SELECTED_LOGS"];
  });

  const isExporting = ref(false);
  const onExport = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "pt",
      format: "A4",
    });

    const tableColumn = [
      { header: "Transaction", dataKey: "transactionId" },
      { header: "Date", dataKey: "date" },
      { header: "User", dataKey: "userName" },
      { header: "Total", dataKey: "amount" },
      { header: "Item", dataKey: "item" },
      { header: "Details", dataKey: "details" },
      { header: "Email", dataKey: "email" },
      { header: "Type", dataKey: "type" },
    ];

    const tableRows = selectedRows.value.map(row => ({
      transactionId: row.transactionId.slice(-5),
      date: new Date(row.date).toLocaleString(),
      userName: row.userName,
      amount: row.amount.toFixed(2),
      item: row.item,
      details: row.details,
      email: row.email,
      type: row.type,
    }));

    doc.autoTable({
      columns: tableColumn,
      body: tableRows,
      styles: {
        fontSize: 10,
        overflow: 'linebreak',
        cellPadding: 5,
      },
      columnStyles: {
        transactionId: { cellWidth: 80 },
        date: { cellWidth: 100 },
        userName: { cellWidth: 70 },
        amount: { cellWidth: 50 },
        item: { cellWidth: 60 },
        details: { cellWidth: 120 },
        email: { cellWidth: 120 },
        type: { cellWidth: 70 },
      },
      margin: { top: 60 },
      headStyles: {
        fillColor: [41, 128, 185],
        textColor: [255, 255, 255],
        fontStyle: 'bold',
      },
    });

    const now = new Date();
    const dateTimeString = now.toISOString().slice(0, 19).replace(/:/g, '-');
    const fileName = `activity_logs_${dateTimeString}.pdf`;

    doc.save(fileName);
    isExporting.value = false;
  };

  const onExportCSV = () => {
    const headers = [
      "Transaction",
      "Date",
      "User",
      "Total",
      "Item",
      "Details",
      "Email",
      "Type",
    ];
    console.log(toRaw(store.getters["activityLogs/GET_SELECTED_LOGS"]));
    console.log('---')
    console.log(toRaw(store.getters["activityLogs/GET_LOGS"]));
    const selectedLogs = toRaw(store.getters["activityLogs/GET_SELECTED_LOGS"])
    console.log({ selectedLogs });
    const allLogs = toRaw(store.getters["activityLogs/GET_LOGS"])
    const rows = selectedLogs?.length > 0 ? selectedLogs : allLogs;
    console.log(rows.length);
    const csvContent = [
      headers.join(','),
      ...rows.map(row => {
        const transId = '#' + row.transactionId.slice(-5).toString();
        const values = [
          transId,
          new Date(row.date).toLocaleString(),
          row.userName,
          row.amount.toFixed(2),
          row.item,
          row.details,
          row.email,
          row.type,
        ];
        return values.map(value => `"${value}"`).join(',');
      }),
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const now = new Date();
    const dateTimeString = now.toISOString().slice(0, 19).replace(/:/g, '-');
    const fileName = `activity_logs_${dateTimeString}.csv`;

    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    isExporting.value = false;
  };

</script>

<template>
  <app-main-content>
    <section class="c-activity-logs d-flex justify-content-between align-items-center">
      <h1 class="c-activity-logs__heading">Activity</h1>
      <el-button
        class="el-button el-button--large el-button--success c-activity-logs__button"
        @click="onExportCSV"
        :loading="isExporting"
      >
        Export
      </el-button>
    </section>

    <SelectOrganizationWarning v-if="showSelectOrganization" type="logs" />

    <section v-else class="bg-white rounded pb-4 c-activity-logs-table">
      <article class="px-4 py-4 border-bottom d-flex justify-content-between align-items-center">
        <div class="d-flex gap-4">
          <el-link :underline="false" class="fs-20 fw-bold text-black fs-20">Transactions</el-link>
          <template v-if="!isOrgAdmin">
            <el-link :underline="false" class="fs-20 fw-bold text-black-50" href="/manage-resources/todos">Resources</el-link>
            <el-link :underline="false" class="fs-20 fw-bold text-black-50" href="/manage-content/events">Events</el-link>
            <el-link :underline="false" class="fs-20 fw-bold text-black-50" href="/manage-content/directory/products">Products</el-link>
            <el-link :underline="false" class="fs-20 fw-bold text-black-50" href="/manage-content/directory/roles">Roles</el-link>
            <el-link :underline="false" class="fs-20 fw-bold text-black-50" href="/manage-content/directory/security">Security</el-link>
          </template>
        </div>
        <div class="d-flex gap-4">
          <el-link href="https://causes.benevity.org/" target="_blank" :underline="false" class="text-success fw-bold fs-6" v-if="isOrgAdmin">Benevity Payouts</el-link>
          <el-link href="https://connect.stripe.com/" target="_blank" :underline="false" class="text-success fw-bold fs-6">Stripe Account</el-link>
        </div>
      </article>
      <article class="p-4">
        <ActivityTableFilters />
      </article>
      <ActivityTable />
    </section>

  </app-main-content>
</template>

<style lang="scss">
.c-activity-logs {
  margin-bottom: 19px;
  &-table {
    .el-checkbox {
      &__label {
        display:none;
      }
    }
  }

  &__heading {
    font-size: 40px;
    margin-bottom: 0;
  }

  &__button {
    height: 40px;
    width: 94px;
    font-size: 16px;
    span {
      font-weight: 700;
    }
  }
}
</style>
