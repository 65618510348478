import {reactive, ref} from "vue";

const defaultState = () => ({
  id: null,
  title: '',
  description: '',
  url: '',
  tags: ref([]),
  cost: 0,
  campaignType: null,
  causes: ref([]),
  maxQuantity: 0,
  maxDonation: null,
  donationMultiplier: 1,
  impact: null,
  startDate: null,
  endDate: null,
  eventDate: null,
  startDateTimeZone: null,
  endDateTimeZone: null,
  eventDateTimeZone: null,
  waiverURL: '',
  varients: [],
  location: null,
  status: null,
  quickCosts: [null, null, null, null],
  glow: {
    glowStartDate: null,
    glowEndDate: null,
    glowColor: null,
    glowStartDateTimeZone: null,
    glowEndDateTimeZone: null,
  },
  isFeatured: null,
  sweepstakesSticker: {
    aboutTheCause: null,
    approximateRetailValue: null,
    checkoutPageLegal: null,
    createdAt: null,
    drawingDateTime: null,
    drawingDateTimeTimezone: null,
    entries: [null],
    entryCost: [null],
    experienceDateTime: null,
    experienceDateTimeTimezone: null,
    nonProfitBeneficiary: null,
    numberOfPrizesAndWinners: null,
    prizes: null,
    sweepstakesConfirmation: null,
    sweepstakesLoserMessage: null,
    sweepstakesWinnerMessage: null,
  },
  getSweepstakesWinners:([{
      id: null,
      stickerId: null,
      entityId: null,
      participantName: null,
      participantAvatar: null,
      eligible: null,
    }]),
  confirmationEmail: null,
  reminderEmail: null,
});

export default {
  namespaced: true,

  state: {
    item: defaultState()
  },

  mutations: {
    MUTATE_STICKER(state, payload) {
      Object.assign(state.item, payload);
    },
  },

  actions: {
    SET_STICKER({commit}, item) {
      commit('MUTATE_STICKER', item);
    },
    RESET_TO_DEFAULT({commit}) {
      commit('MUTATE_STICKER', defaultState());
    },
  },

  getters: {
    GET_STICKER: (state) => state.item
  },
};
