<script setup lang="ts">
import shared from '@/views/manage-content/media/shared';
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import { defineProps } from "vue";

const { removeItemGetConfirm, removeSticker, approveSticker } = shared();

const route = useRoute();
const router = useRouter();
const item = store.getters['sticker/GET_STICKER'];
const { id } = route.params;

const props = defineProps<{
  isEditPage: boolean;
  isAdmin: boolean;
  stickerType: string;
  isActive: boolean;
  fetchSticker: () => Promise<void>;
}>();

const { isEditPage, fetchSticker } = props;

function removeItem() {
  removeItemGetConfirm(async () => {
    await removeSticker(item.id);
    router.push('/manage-content/media/stickers');
  });
}

async function publishItem(opt) {
  let status = 'Active';
  if(opt === false) status = 'Archived';
  await approveSticker({
    id,
    status: status,
  });
  await fetchSticker();
}
</script>

<template>
  <article class="c-breadcrumb-actions">
    <section class="c-add-sticker__breadcrumb" @click="router.push('/manage-content/media/stickers')">
      <fai height="40px" icon="arrow-left" type="fas" width="40px" />
      <h1 class="c-breadcrumb-actions__title">{{ isEditPage ? 'Edit ': 'Add '}} Sticker</h1>
    </section>

    <section v-if="isEditPage && isAdmin">
      <el-button type="danger" plain @click="removeItem" class="has-fai">
        <fai type="fal" icon="trash" class="size-18 me-2"></fai>
        <span class="min-w-110 d-inline-block">Delete</span>
      </el-button>

      <el-button
        v-if="!item.seen || (item.seen && item.published)"
        type="danger"
        @click="publishItem(false)"
        class="has-fai"
      >
        <fai type="fal" icon="times-circle" class="size-18 me-2"></fai>
        <span class="min-w-110 d-inline-block">Deny</span>
      </el-button>
      <el-button
        v-if="!item.seen || (item.seen && !item.published)"
        type="success"
        :style="{ color: '#1EBEAF' }"
        @click="publishItem(true)"
        class="has-fai"
      >
        <fai type="fal" icon="check-circle" class="size-18 me-2" style="color: #fff"></fai>
        <span class="min-w-110 d-inline-block text-white">Approve</span>
      </el-button>
    </section>

  </article>
</template>

<style scoped lang="scss">
.c-breadcrumb-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__title {
    color: black;
    font-size: 40px;
    margin: 0 0 0 15px;
    font-weight: 900;
  }

}
</style>
