<template lang="pug">
div
  .card-header.p-4.bg-transparent
    .row.row-sm.align-items-center
      .col
        h4.mb-0 Organization Stickers
      .col-auto
        el-button(type="primary"
        @click="$router.push(`/manage-content/media/add-stickers-v2`)").has-fai
          fai(type="fal" icon="plus-circle").size-18.me-2
          span.min-w-110.d-inline-block Add New Sticker
      .col-auto(v-if="!stripe_onboarded")
        el-button(type="primary" @click="onBoardOnStripe()").has-fai
          fai(type="fal" icon="plus-circle").size-18.me-2
          span.min-w-110.d-inline-block Onboard On Stripe

  el-table(
    :data="items.items"
    stripe
    v-loading="loading"
    @row-click="$router.push(`stickers/${$event.id}`);"
    style="width: 100%"
  ).clickable-rows
    el-table-column(width="15")
      // space

    el-table-column(width="70")
      template(#default="scope")
        img(:src="scope.row.url").size-50px.obj-f-c
        //- img(:src="scope.row.stickerUrl").size-50px.obj-f-c
        // img(:src="`//placekitten.com/g/50/50`").size-50px.obj-f-c

    el-table-column(prop="title" label="Title" width="200")
    el-table-column(prop="published" label="Status" width="200" )
      template(#default="scope")
        | {{ stickerState(scope.row) }}
    el-table-column(label="Tags" min-width="200")
      template(#default="scope")
        span {{ scope.row.tags ? scope.row.tags.split(" |=| ").join(", ") : "" }}
    el-table-column(label="Cost" min-width="200")
      template(#default="scope") $ {{ scope.row.cost }}

    el-table-column(width="46")
      .el-table__expand-icon
        img(src="@/assets/icons/light/chevron-right-light.svg")

    el-table-column(width="15")
      // space

  //- dialog-add-sticker(
  //-   v-if="dialog"
  //-   :loading="loading"
  //-   v-model="dialog"
  //-   @save-item="saveItem"
  //-   @close-dialog="dialog = false"
  //- )
</template>

<script>

import { reactive, onMounted, defineComponent } from 'vue';
import DialogAddSticker from './DialogAddSticker.vue';
import shared from '../shared';

export default defineComponent({
  name: 'ManageContent_Media_Stickers',
  components: { DialogAddSticker },
  props: {
    stripe_onboarded: { type: Boolean, default: false },
  },
  setup() {
    const {
      route,
      ref,
      getOrgStickers,
      updateOrgSticker,
      doGetStripeAuthorizeUrl,
      loading,
    } = shared();
    const items = reactive({ items: [] });
    const { orgId } = route.params;
    const dialog = ref(false);
    async function getItems() {
      try {
        loading.value = true;
        items.items = await getOrgStickers(orgId);
      } catch (err) {
        console.log(err);
      } finally {
        loading.value = false;
      }
    }

    async function saveItem(input) {
      await updateOrgSticker(orgId, input);
      await getItems();
    }

    function stickerState(itm) {
      if (itm.seen) {
        return itm.published ? 'Approved' : 'Not Approved';
      }
      return 'Pending';
    }

    async function onBoardOnStripe() {
      console.log('----------------------------------');
      const stripeLink = await doGetStripeAuthorizeUrl(orgId);
      console.log(stripeLink);
      window.location.href = stripeLink;
      console.log('----------------------------------');
    }

    onMounted(async () => {
      await getItems();
    });

    return {
      items,
      loading,
      dialog,
      // methods
      saveItem,
      stickerState,
      onBoardOnStripe,
    };
  },
});
</script>
