<script lang="ts" setup>
import {computed, onMounted, ref} from 'vue';

import sharedOrg from '@/views/manage-users-and-organizations/shared';
import shared from '@/views/manage-content/media/shared';
import fileUpload from "@/shared/upload";

import StickerContent from './components/StickerContent.vue';
import DefaultSticker from "@/views/manage-content/media/add-sticker/components/DefaultSticker.vue";
import CampaignSticker from "@/views/manage-content/media/add-sticker/components/CampaignSticker.vue";
import TicketSticker from "@/views/manage-content/media/add-sticker/components/TicketSticker.vue";
import SweepstakesSticker from './components/SweepstakesSticker.vue';
import SelectOrganizationWarning from "@/components/SelectOrganizationWarning.vue";
import router from "@/router";
import { useRoute } from "vue-router";
import BreadcrumbActions from "@/views/manage-content/add-item/BreadcrumbActions.vue";
import Insights from "@/views/manage-content/add-item/Insights.vue";
import {uploadDefaultStickerMap, uploadTicketStickerMap} from '@/shared/upload-sticker-model'
import { useStore } from 'vuex';
const store = useStore();
const route = useRoute();
const isEditPage = ref(false);

const {uploadStatus, handleUploadRequest} = fileUpload('stickers', 'Sticker');

const winnerName = ref<string | null>(null);
const errors = ref<string[]>([]);
const winners = ref<Winner[] | null>(null);
const selectedWinners = ref<Winner[]>([]);
const clickCount = ref(0);
const isTextVisible = ref(false);
const isStickerSold = ref(false);
const stickerUrl = ref('');

import QrcodeVue from "qrcode.vue";

const {
  updateSticker,
  sendLoserEmails,
  getBullhornsAsTags,
  getcausesAsDropdown,
  getSticker,
  dateAndTime,
  drawWinner,
  getWinners,
  findStickerInsights
} = shared();
const { updateOrgSticker } = sharedOrg();

const stickerType = ref('default'); // default, campaign, ticket, sweepstakes

interface TagData {
  value: string;
  label: string;
}
interface CauseData {
  value: string;
  label: string;
}

interface Winner {
  id: string;
  stickerId: string;
  entityId: string;
  participantName: string;
  participantAvatar: string;
  eligible: boolean;
  dateCreated: string;
  dateUpdated: string;
}

const item = store.getters['sticker/GET_STICKER'];

const onUpload = async () => {
  errors.value = [];
  if (stickerType.value === 'default') {
    await uploadDefaultSticker();
  } else if (stickerType.value === 'campaign') {
    await uploadCampaignSticker();
  } else if (stickerType.value === 'ticket' ) {
    await uploadTicketSticker();
  } else if (stickerType.value === 'sweepstakes') {
    await uploadSweepstakesSticker();
  }
}

const onDraw = async () => {
    errors.value = [];
    const sweepstakesStickerId = item.id;
    const participantIds= selectedWinners.value.map(winner => winner.entityId);
    const validParticipantIds = participantIds.length === 0 ? null : participantIds;

    return await drawWinner(sweepstakesStickerId, validParticipantIds);
};

const getWinnersList = async () => {
    try {
        const sweepstakesStickerId = item.id;
        const response = await getWinners(sweepstakesStickerId);
        winners.value = response;
        if (winners.value) {
            winnerName.value = winners.value.map(winner => winner.participantName).join(', ');
        } else {
            winnerName.value = '';
        }

    } catch (error) {
        console.error('Error fetching winners:', error);
    }
};

const loserEmails = async () => {
  try {
    await sendLoserEmails(item.id);
  } catch (error) {
      console.error('Error fetching is looser email sent:', error);
  }
};

const handleButtonClick = async() => {
    clickCount.value += 1;

    // If button clicked second time show text and change class
    if (clickCount.value === 1) {
      isTextVisible.value = true;

      await getWinnersList();
    }

    // If third time clicked, close window and reset click counter
    if (clickCount.value === 2) {
      await onDraw();
      isTextVisible.value = false;
      clickCount.value = 0;
    }
  };

  const handleClose = async() => {
      isTextVisible.value = false;
      clickCount.value = 0; // Resetuje brojač klikova
      await getWinnersList();
    };

const onAddImage = async (payload: any) => {
  await handleUploadRequest(payload);
  if (uploadStatus.status === 'ok') {
    item.url = uploadStatus.resourceUrl;
  }
}

const isAdmin = computed(() => store.getters['users/isAdmin']);
const isOrgAdmin = computed(() => store.getters['users/isOrgAdmin']);
const selectedOrganization = computed(() => store.getters["organizations/GET_SELECTED_ORGANIZATION"]);

const checkErrorsAndGenerateMessages = () => {
  // shared on all
  if (!item.title.length) errors.value.push('Title');
  if (!item.url.length) errors.value.push('Image');
  
  const descriptionContent = item.description || "";
  if (descriptionContent.replace(/<[^>]*>/g, '').trim() === "") {
    errors.value.push('Details');
  }

  if (stickerType.value === 'default' || stickerType.value === 'campaign' || stickerType.value === 'ticket') {
    if (!item.tags.length) errors.value.push('Tags');
  }

  if (stickerType.value === 'campaign' || stickerType.value === 'ticket') {
    if (!item.startDateTimeZone) errors.value.push('Timezone');
  }

  if (stickerType.value === 'campaign') {
    if (!item.causes.length) errors.value.push('Non Profit')
  }

  // shared on campaign, ticket and sweepstakes
  if (stickerType.value === 'campaign' || stickerType.value === 'ticket' || stickerType.value === 'sweepstakes') {
    if (!item.startDate) errors.value.push('Start Date & Time');
    if (!item.endDate) errors.value.push('End Date & Time');
  }

  // only ticket has event date required
  if (stickerType.value === 'ticket') {
    if (!item.eventDate) errors.value.push('Event Date & Time');
    if (!item.varients?.length) errors.value.push('Tier is required')
  }

  // only for sweepstakes
  if (stickerType.value === 'sweepstakes') {
    if (!item.sweepstakesSticker.drawingDateTime) {
      errors.value.push('Drawing Date & Time');
    }

    const entries = item.sweepstakesSticker.entries;
    const entryCosts = item.sweepstakesSticker.entryCost;

    if (!Array.isArray(entries) || entries.length === 0) {
      errors.value.push('Entries');
    } else {
      for (const [index, entry] of entries.entries()) {
        if (typeof entry !== 'number' || entry <= 0) {
          errors.value.push('Entries');
        }
      }
    }

    if (!Array.isArray(entryCosts) || entryCosts.length === 0) {
      errors.value.push('Entry Costs');
    } else {
      for (const [index, cost] of entryCosts.entries()) {
        if (typeof cost !== 'number' || cost < 0) {
          errors.value.push('Entry Costs');
        }
      }
    }
  }
}

const redirectToStickersPage = async() => {
  if (isOrgAdmin.value) {
    return await router.push(`/manage/orgs/${selectedOrganization.value.id}/stickers`);
  }
  return router.push('/manage-content/media/stickers');
}

const uploadDefaultSticker = async () => {
  checkErrorsAndGenerateMessages();
  if (errors.value.length) return window.scrollTo(0, 0);

  const updatedItem = uploadDefaultStickerMap(item);

  await updateAdminOrOrgAdminSticker(updatedItem);
  redirectToStickersPage();
}

const uploadCampaignSticker = async () => {
  checkErrorsAndGenerateMessages();
  if (errors.value.length) return window.scrollTo(0, 0);
  const updatedItem = item;

  const cQuickCosts: number[] = [];
  const qc = item.quickCosts || [];
  qc.map((q) => {
    if (typeof q === 'number') {
      cQuickCosts.push(q);
    }
  })

  updatedItem.quickCosts = cQuickCosts;
  updatedItem.cost = parseFloat(parseFloat(updatedItem.cost).toFixed(2));
  if (Array.isArray(updatedItem.tags)) {
    updatedItem.tags = updatedItem.tags.join(' |=| ');
  } else if (typeof updatedItem.tags === 'string') {
    updatedItem.tags = updatedItem.tags.split(',').join(' |=| ');
  }

  if (Array.isArray(updatedItem.causes)) {
    updatedItem.causes = updatedItem.causes.join(' |=| ');
  } else if (typeof updatedItem.causes === 'string') {
    updatedItem.causes = updatedItem.causes.split(',').join(' |=| ');
  }

  item.campaignType = 'Campaign';
  updatedItem.varients = [];
  updatedItem.sweepstakesSticker = null;
  const stickerProps = getStickerProperties(updatedItem);
  await store.dispatch('sticker/SET_STICKER', stickerProps);
  await updateAdminOrOrgAdminSticker(stickerProps);
  await router.push('/manage-content/media/stickers');
}

const uploadTicketSticker = async () => {
  checkErrorsAndGenerateMessages();
  if (errors.value.length) return window.scrollTo(0, 0);

  const updatedItem = uploadTicketStickerMap(item);
  if (updatedItem.varients?.length) {
    updatedItem.cost = 0;
  }
  await store.dispatch('sticker/SET_STICKER', updatedItem);
  await updateAdminOrOrgAdminSticker(updatedItem);
  redirectToStickersPage();
};


const uploadSweepstakesSticker = async () => {
  checkErrorsAndGenerateMessages();
  if (errors.value.length) return window.scrollTo(0, 0);

  const addItem = { ...item };
  addItem.cost = 0;
  addItem.tags = null;
  addItem.campaignType = "Sweepstakes";
  addItem.isFeatured = false;
  addItem.IsCausesSearchAllowed = false;
  addItem.varients = [];

  if(addItem.sweepstakesSticker.numberOfPrizesAndWinners == null || addItem.sweepstakesSticker.numberOfPrizesAndWinners == "") {
    addItem.sweepstakesSticker.numberOfPrizesAndWinners = 1;
  }

  if (Array.isArray(item.causes)) {
    addItem.causes = item.causes.join(' |=| ');
  } else if (typeof item.causes === 'string') {
    addItem.causes = item.causes.split(',').join(' |=| ');
  } else {
    addItem.causes = '';
  }

  addItem.sweepstakesSticker.nonProfitBeneficiary = addItem.causes;

  const updatedItem = getSweepstakesStickerProperties(addItem);

  await store.dispatch('sticker/SET_STICKER', updatedItem);

  await updateAdminOrOrgAdminSticker(updatedItem);
  await router.push('/manage-content/media/stickers');
  redirectToStickersPage();
}

const updateAdminOrOrgAdminSticker = async (sticker:any) => {
  return isOrgAdmin.value ? await updateOrgSticker(selectedOrganization.value.id, sticker) : await updateSticker(sticker);
}

const tagsList = ref<TagData[]>([]);
const tagsArray = ref<string[]>([]);
async function getBullhornsAsTagsList() {
const bullhornTags = await getBullhornsAsTags();

bullhornTags.forEach((element: any) => {
    const data: TagData = { value: `${element.bullhorn}`, label: `${element.bullhorn}` };
    tagsList.value.push(data);
    // tagsArray is used to render the correct values for the new dropdown
    tagsArray.value.push(element.bullhorn);
  });
}

const causeList = ref<CauseData[]>([]);
const causeOptionsArray = ref<string[]>([]);

const causeLoading = ref(false);
async function getCausesList(query:any) {
  causeLoading.value = true;
  const benevityCauses = await getcausesAsDropdown(query);
  causeLoading.value = false;
  benevityCauses.forEach((element:any) => {
    const data = {
      value: `${element.name} : ${element.id}`,
      label: `${element.name} : ${element.id}`,
    };
    causeList.value.push(data);
    causeOptionsArray.value.push(`${element.name} : ${element.id}`);
  });
}

const isLoading = ref(false);
const decideIfEditAndFetch = async() => {
  if (route.params?.id) {
    isEditPage.value = true;
    await fetchSticker();
  }
}

const isActive =  () =>{
  if(item.status === 'Active') {
    return true;
  } else {
    return false;
  }
};

const fetchSticker = async () => {
  isLoading.value = true;
  const item = await getSticker(route.params.id);
  await populateItem(item);
  await store.dispatch('sticker/SET_STICKER', item);
  if (item.id) {
    stickerUrl.value = `${import.meta.env.VITE_WEB_BASE_URL}/web/sticker/${item.id}`;
  }
  isLoading.value = false;
}

const populateItem = async (item:any) => {
  item.tags = (typeof item.tags === 'string' && item.tags !== '') ? item.tags.split(' |=| ') : [];
  item.causes = (typeof item.causes === 'string' && item.causes !== '') ? item.causes.split(' |=| ') : [];
  [item.postDate, item.postTime] = dateAndTime(item.createdAt);
  item.varients = item.varients.filter((x:any) => x.isActive === true);

  console.log("GLOW ITEM", item.glow);

  item.glow = {
    glowStartDate: item.glow?.glowStartDate,
    glowEndDate: item.glow?.glowEndDate,
    glowColor: item.glow?.glowColor,
    glowStartDateTimeZone: item.glow?.glowStartDateTimeZone,
    glowEndDateTimeZone: item.glow?.glowEndDateTimeZone,
  }

  await store.dispatch('sticker/SET_STICKER', {
    endDate: item.endDate,
    startDate: item.startDate
  });

  switch (item.campaignType) {
    case 'Ticket':
      stickerType.value = 'ticket';
      break;
    case 'campaign':
      stickerType.value = 'campaign';
      break;
    case 'Campaign':
      stickerType.value = 'campaign';
      break;
    case 'Sweepstakes':
      stickerType.value = 'sweepstakes';
      break;
    case null:
      stickerType.value = 'default';
      break;
    default: stickerType.value = 'default';
  }

}

onMounted(async () => {
  await store.dispatch('sticker/RESET_TO_DEFAULT'); // reset so the state does not persist from before
  await decideIfEditAndFetch();
  await getBullhornsAsTagsList();
  await getCausesList('');
  if(isEditPage.value) {
    await getStickerInsights();
    if (isStickerSold.value) {
      await getWinnersList();
    }
  }
})

async function getStickerInsights() {
  const mStickerInsights = await findStickerInsights(route.params.id);
  if(mStickerInsights.unitsSold > 0) {
    isStickerSold.value = true;
  }
}

const handleClick = async () => {
  if (stickerType.value === 'sweepstakes' && item.status === 'Active' && isEditPage.value) {
    await onDraw();
    await getWinnersList();
  } else {
    onUpload();
  }
};

const convertToTimeZone = (dateTime, timeZone) => {
  return new Date(
    new Intl.DateTimeFormat('en-US', {
      timeZone,
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    }).format(new Date(dateTime))
  );
};

const isDrawingDateReached = (drawingDateTime, item) => {
  const drawingDate = convertToTimeZone(drawingDateTime, item.startDateTimeZone);
  const currentDate = new Date();

  return currentDate >= drawingDate;
};


const drawClick = async () => {
  if (stickerType.value === 'sweepstakes' && item.status === 'Active' && isEditPage.value) {
    await onDraw();
    await getWinnersList();
  }
};

const getSweepstakesStickerProperties = (item:any) => {
  return {
    id: item.id,
    title: item.title,
    description: item.description,
    url: item.url,
    tags: item.tags,
    cost: item.cost,
    campaignType: item.campaignType,
    causes: item.causes,
    maxQuantity: item.maxQuantity,
    maxDonation: item.maxDonation,
    donationMultiplier: item.donationMultiplier,
    impact: item.impact,
    startDate: item.startDate,
    endDate: item.endDate,
    waiverURL: item.waiverURL,
    varients: null,
    quickCosts: [],
    glow: item.glow ? {
      glowStartDate: item.glow.glowStartDate,
      glowEndDate: item.glow.glowEndDate,
      glowColor: item.glow.glowColor,
      glowStartDateTimeZone: item.glow.glowStartDateTimeZone,
      glowEndDateTimeZone: item.glow.glowEndDateTimeZone,
    } : null,
    location: item.location,
    sweepstakesSticker: item.sweepstakesSticker ? {
      aboutTheCause: item.sweepstakesSticker.aboutTheCause,
      approximateRetailValue: item.sweepstakesSticker.approximateRetailValue,
      checkoutPageLegal: item.sweepstakesSticker.checkoutPageLegal,
      createdAt: item.sweepstakesSticker.createdAt,
      drawingDateTime: item.sweepstakesSticker.drawingDateTime,
      entries: item.sweepstakesSticker.entries,
      entryCost: item.sweepstakesSticker.entryCost,
      experienceDateTime: item.sweepstakesSticker.experienceDateTime,
      nonProfitBeneficiary: item.sweepstakesSticker.nonProfitBeneficiary,
      numberOfPrizesAndWinners: item.sweepstakesSticker.numberOfPrizesAndWinners,
      prizes: item.sweepstakesSticker.prizes,
      sweepstakesConfirmation: item.sweepstakesSticker.sweepstakesConfirmation,
      sweepstakesLoserMessage: item.sweepstakesSticker.sweepstakesLoserMessage,
      sweepstakesWinnerMessage: item.sweepstakesSticker.sweepstakesWinnerMessage,
      drawingDateTimeTimezone: item.startDateTimeZone || null,
      experienceDateTimeTimezone: item.startDateTimeZone || null,
    } : null,
    startDateTimeZone: item.startDateTimeZone || null,
    endDateTimeZone: item.endDateTimeZone || null,
  };
};

const getStickerProperties = (item:any) => {
  return {
    id: item.id,
    title: item.title,
    description: item.description,
    url: item.url,
    tags: item.tags,
    cost: item.cost,
    campaignType: item.campaignType,
    causes: item.causes,
    maxQuantity: item.maxQuantity,
    maxDonation: item.maxDonation,
    donationMultiplier: item.donationMultiplier,
    impact: item.impact,
    startDate: item.startDate,
    endDate: item.endDate,
    waiverURL: item.waiverURL,
    location: item.location,
    varients: {
      id: item.id,
      cost: item.cost,
      title: item.title,
      sortOrder: item.sortOrder,
      stickerId: item.id,
      description: item.description,
      isActive: item.isActive,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
    },
    glow: item.glow,
    quickCosts: item.quickCosts,
    sweepstakesSticker: null,
    startDateTimeZone: item.startDateTimeZone || null,
    endDateTimeZone: item.endDateTimeZone || null,
    eventDateTimeZone: item.eventDateTimeZone || null,
  };
};
</script>

<template>
<SelectOrganizationWarning v-if="!isAdmin && !selectedOrganization?.id" type="sticker"/>
<article v-else class="c-add-sticker">
  <BreadcrumbActions
      :is-edit-page="isEditPage"
      :sticker-type="stickerType"
      :isActive="isActive()"
      :is-admin="isAdmin"
      :fetch-sticker="fetchSticker"
      :key="isEditPage.toString()"
  />
  <div v-if="isLoading" class="c-loading-spinner"/>
  <section class="c-add-sticker__container" v-else>
      <section class="c-add-sticker__content">
        <article class="c-add-sticker__content__left">
          <StickerContent :onAddImage="onAddImage" :url="item.url"/>
          <section class="c-add-sticker__qr-code" v-if="isEditPage && item && item.id" >
            <b>Share Sticker</b>
            <qrcode-vue :value="stickerUrl" :size="167"></qrcode-vue>
            <a :href="stickerUrl" target="_blank">{{ stickerUrl }}</a>
          </section>

          <section v-if="errors.length" class="alert alert-danger fs-6 mt-3" role="alert">
            Please input required fields: <b v-for="(error, index) in errors">{{error}}{{ index < errors.length - 1 ? ', ' : '' }}</b>
          </section>
        </article>

        <article class="c-add-sticker__content__right">
          <DefaultSticker v-if="stickerType === 'default'" :availableTags="tagsArray"/>
          <CampaignSticker v-if="stickerType === 'campaign'" :availableTags="tagsArray" :causes="causeOptionsArray" @onFetchCauses="getCausesList" :cause-loading="causeLoading"/>
          <TicketSticker v-if="stickerType === 'ticket'" :availableTags="tagsArray" :causes="causeOptionsArray" @onFetchCauses="getCausesList" :cause-loading="causeLoading"/>
          <template v-if="stickerType === 'sweepstakes'">
            <SweepstakesSticker
              :class="{ 'disable-and-opacity': item.status === 'Active' }"
              :item="item"
              :disabled="item.status === 'Active'"
              :causes="causeOptionsArray"
              @onFetchCauses="getCausesList" 
              :cause-loading="causeLoading"
            />
          </template>

          <article v-if="stickerType === 'sweepstakes'" class="s-sweepstakes-sticker__row s-sweepstakes-sticker__row--column" style="margin-top: 30px;">
            <p>
              By uploading you agree to our 
              <a href="https://www.spotlight.xyz/legal-stuff/sweepstakes-rules#age-restrictions" target="_blank" rel="noopener noreferrer">Age Restrictions</a>, 
              <a href="https://www.spotlight.xyz/legal-stuff/sweepstakes-rules#eligible-countries" target="_blank" rel="noopener noreferrer">Eligible Countries</a>, 
              <a href="https://www.spotlight.xyz/legal-stuff/sweepstakes-rules#donation-transparency" target="_blank" rel="noopener noreferrer">Donation Transparency</a>, 
              <a href="https://www.spotlight.xyz/legal-stuff/sweepstakes-rules#selection-process" target="_blank" rel="noopener noreferrer">Selection Process</a>, and 
              <a href="https://www.spotlight.xyz/legal-stuff/sweepstakes-rules#disclaimer" target="_blank" rel="noopener noreferrer">Disclaimer</a>.
            </p>
          </article>

          <!-- UPLOAD TO SPOTLIGHT CONTAINER -->
          <div class="" v-if="!isEditPage">
            <el-button
              class="c-add-sticker__content__submit"
              type="success"
              @click="handleClick">

            <fai class="size-14" icon="upload" type="fas"/>
              Upload to Spotlight
            </el-button>
          </div>

          <!-- SAVE STICKER AFTER EDIT CONTAINER -->
          <div class="" v-else-if="isEditPage && stickerType !== 'sweepstakes' || (stickerType === 'sweepstakes' && !isActive())">
            <el-button
              class="c-add-sticker__content__submit"
              type="success"
              @click="handleClick">

              <fai class="size-14" icon="floppy-disk" type="fas"/>
              Save Sticker

            </el-button>
          </div>

          <!-- DRAW STICKER CONTAINER -->
          <div v-if="isEditPage && stickerType == 'sweepstakes' && winners === null && isActive()">
            <el-button
              class="c-add-sticker__content__submit"
              type="success"
              @click="drawClick"
              :disabled="!isStickerSold || !isDrawingDateReached(item.sweepstakesSticker.drawingDateTime, item)"
              >
              <fai class="size-14" icon="repeat" type="fas"/>
              Draw
            </el-button>
          </div>

          <!-- startDateTimeZone -->

          <!-- REDRAW WINNER CONTAINER -->
          <div v-else-if="isEditPage && stickerType == 'sweepstakes' && winners !== null">
            <div :class="isTextVisible ? 'c-add-sticker__winners-container' : ''" style="position: relative; padding: 16px;">
              
              <!-- Close Button (X) in Top Right Corner -->
              <button 
                v-if="isTextVisible" 
                @click="handleClose"
                style="position: absolute; top: 8px; right: 8px; background: none; border: none; font-size: 20px; color: #333; cursor: pointer;">
                &times;
              </button>
              
              <div v-if="isTextVisible" class="c-add-sticker__winners-list">
                <p>Choose who to redraw for</p>
                <ul style="list-style: none; padding-left: 0;">
                  <li v-for="winner in winners" :key="winner.id" class="winner-item" style="position: relative; display: flex; align-items: center; margin-bottom: 8px;">
                    <img
                      v-if="winner.participantAvatar"
                      :src="winner.participantAvatar"
                      alt="Winner Image"
                      style="width: 20px; height: 20px; margin-right: 8px; border-radius: 50%;"
                    />
                    <fai
                      v-else
                      class="icon-size-14"
                      type="fas"
                      icon="user-circle"
                      style="width: 20px; height: 20px; margin-right: 8px;"
                    />
                    <label :for="winner.id" style="c-add-sticker__winners-list">{{ winner.participantName }}</label>
                    <el-checkbox
                      v-model="selectedWinners"
                      :id="winner.id"
                      :value="winner"
                      :style="{ marginLeft: 'auto' }"
                      class="c-quick-costs"
                    />
                  </li>
                </ul>
              </div>

              <!-- Redraw and Confirm Buttons -->
              <div class="buttons-row">
                <el-button
                  class="c-add-sticker__content__submit"
                  type="success"
                  @click="handleButtonClick">
                  <template v-if="stickerType === 'sweepstakes' && isEditPage">
                    <fai class="size-14" icon="repeat" type="fas"/>
                    Redraw
                  </template>
                </el-button>
                <el-button 
                  v-if="!isTextVisible"
                  class="c-add-sticker__content__submit"
                  type="success"
                  @click="loserEmails"
                >
                  Confirm
                </el-button>
              </div>
            </div>
          </div>
        </article>
      </section>

      <section class="c-add-sticker__extra" :class="{ 'disabled-section': stickerType === 'sweepstakes' && item.status === 'Active' }">
        <article class="c-add-sticker__extra__type">
          <span class="c-add-sticker__extra__title mb-1">Sticker Type</span>
          <el-select
            v-model="stickerType"
            class="c-input c-input__select"
            :disabled="stickerType === 'sweepstakes' && item.status === 'Active'"
          >
            <el-option v-if="!(isEditPage && stickerType === 'sweepstakes')" label="Default" value="default">Default</el-option>
            <el-option v-if="!(isEditPage && stickerType === 'sweepstakes')" label="Campaign" value="campaign">Campaign</el-option>
            <el-option v-if="!(isEditPage && stickerType === 'sweepstakes')" label="Ticket" value="ticket">Ticket</el-option>
            <el-option v-if="!(isEditPage && stickerType !== 'sweepstakes')"label="Sweepstakes" value="sweepstakes">Sweepstakes</el-option>
          </el-select>
        </article>
        <Insights class="c-add-sticker__extra__type" v-if="isEditPage" />
      </section>
    </section>
</article>
</template>

<style lang="scss" src="./AddSticker.scss"/>
