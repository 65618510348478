<template lang="pug">

.d-flex.align-items-center
  VueMultiselect(
                id="ajax"
                v-model="filter",
                :multiple="false",
                :options="items.items",
                :searchable="true",
                :close-on-select="true",
                :show-labels="false"
                label="name"
                placeholder="Search Organization"
                :options-limit="300",
                @select="routeToOrg"
                track-by="id"
                :loading="loading"
              )
                template(#option="{ option }")
                  img.rounded-circle.w-40.h-40(v-if="option.avatar" :src="option.avatar")
                  div.avatar-placeholder.w-40.h-40(v-else)
                  span.option__title.px-10 {{ option.name }}




</template>

<script>
import {
  defineComponent, ref, onMounted, reactive, computed, watch,
} from 'vue';
import shared from '@/views/manage-users-and-organizations/shared';
import { useStore } from 'vuex';
import VueMultiselect from 'vue-multiselect';
import globalShared from '@/shared';

export default defineComponent({
  name: 'OrgsDropdown',
  components: { VueMultiselect },
  setup() {
    const store = useStore();
    const {
      router,
      loading,
      getAllOrganizations,
      getPendingOrganizations,
    } = shared();

    const {
      isOrgAdmin
    } = globalShared();

    const filter = ref('');
    const items = reactive({ items: []});
    const serverError = ref('');
    const sendingInvite = ref(false);

    const filtered = computed(() => {
      if (filter.value === '') return items.items;
      const regex = new RegExp(`${filter.value}`, 'gi');
      return items.items.filter((x) => (x.name ? x.name.match(regex) : false));
    });

    function routeToOrg(itm) {
      if (itm.onboarded) {
        router.push(`/manage/orgs/${itm.id}/edit`);
      }
    }

    async function findOrganizations(name) {
      if(store.getters['users/isOrgAdmin'] || store.getters['users/isAdmin']){
      loading.value = true;
      try {
        if (name === 'ManageOrganizations') {
          items.items = await getAllOrganizations();
        } else {
          items.items = await getPendingOrganizations();
        }
      } catch (err) {
        console.log(err);
      } finally {
        loading.value = false;
        // console.log('finally');
        // sort the items in alphabetical order
        items.items.sort((a, b) => (a.name > b.name ? 1 : -1));
        // we only want to show approved orgs in the dropdown
        items.items = items.items.filter((x) => {
          if (!x.onboarded) {
            return false;
          }
          return true;
        });
      }
    }
    else{
      return;
    }
    }

    function showOnboardedStatus(item) {
      return item.onboarded ? 'Onboarded' : '--';
    }

    function showActiveStatus(item) {
      return item.active ? 'Yes' : 'No';
    }


    // const selectedOrganization = store.getters["organizations/GET_SELECTED_ORGANIZATION"]});

    onMounted(async () => {
      await findOrganizations('ManageOrganizations');
      await store.dispatch("organizations/SET_SELECTED_ORGANIZATION", null)
    });

    watch(filter, (newVal) => {
      store.dispatch("organizations/SET_SELECTED_ORGANIZATION", newVal)
    });

    return {
      // data (static)
      loading,
      filter,
      // data (reactive)
      items,
      serverError,
      sendingInvite,
      filtered,
      isOrgAdmin,

      // methods
      showOnboardedStatus,
      showActiveStatus,
      routeToOrg,
    };
  },
});
</script>
