<template lang="pug">
app-main-content-layout(title="Stickers")
  div
    div.p-4.bg-transparent
      .row.row-sm.align-items-center
        .col

        .col-auto
          el-button(type="primary" @click="redirectToCreateSticker").has-fai
            fai(type="fal" icon="plus-circle").size-18.me-2
            span.min-w-110.d-inline-block Add New Sticker
        .col-auto(v-if="!stripeOnboarded")
          el-button(type="primary" @click="onBoardOnStripe()").has-fai
            fai(type="fal" icon="plus-circle").size-18.me-2
            span.min-w-110.d-inline-block Onboard On Stripe

    div.filter-container
      span.padding-left-20(@click="headerFilter('ALL')"
      :class="getClass('ALL')") All
      span.padding-left-20(@click="headerFilter('approved')"
        :class="getClass('approved')") Approved
      span.padding-left-20(@click="headerFilter('pending')"
    :class="getClass('pending')") Pending
      span.padding-left-20(@click="headerFilter('notApproved')"
    :class="getClass('notApproved')") Not Approved

    .row.row-sm.align-items-center
      .col
        el-input(
          v-model="textFilter"
          placeholder="Filter Stickers"
          prefix-icon="el-icon-search"
          @change="filter2"
        )
          template(#prefix='')
            img(
              src="@/assets/icons/light/magnify-light.svg",
              style="font-size: 20px;")
      .col-auto()
         el-button(type="info" v-show="isFiltered" @click="clearTextFilter").has-fai
          fai(type="fal" icon="times").size-18.me-2
          span.min-w-110.d-inline-block Clear Filter
      .col
          VueMultiselect(
            v-model="sortBy",
            :multiple="false",
            :options="sortOptions",
            :searchable="false",
            :close-on-select="true",
            :show-labels="false"
            label="label"
            placeholder="Sort"
            @close="sortTable"
          )

    div.margin-top-20(v-show="selected.length >0" )
      .row.row-sm.align-items-center
        .col {{selected.length}} selected
        .col-auto()
          el-button(type="info" plain v-show="selected.length >0"
          v-if="currentView === 'ALL' || currentView === 'approved'"
          @click="bulkUpdate('Archived')").has-fai
            fai(type="fal" icon="times").size-18.me-2
            span.min-w-110.d-inline-block Archive

    el-table(
      :data="items.items"
      v-loading="loading"
      @row-click="$router.push(`/manage-content/media/stickers-v2/${$event.id}`);"
      style="width: 100%"
      @selection-change="onCheckboxChange"
    ).clickable-rows.margin-top-20
      el-table-column(width="15")
        // space

      el-table-column(type="selection" width="55")

      el-table-column(width="170")
        template(#default="scope")
          img(:src="scope.row.url").size-100px.obj-f-c
          //- img(:src="scope.row.stickerUrl").size-50px.obj-f-c
          // img(:src="`//placekitten.com/g/50/50`").size-50px.obj-f-c

      el-table-column(prop="title" label="Title" width="300")
      el-table-column(prop="published" label="Status" width="140" class="table-title")
        template(#default="scope")
          div(:class="getButtonClass(stickerState(scope.row))") {{ stickerState(scope.row) }}
      el-table-column(label="Type" width="200")
          span Sticker

      el-table-column(width="46")
        .el-table__expand-icon
          img(src="@/assets/icons/light/chevron-right-light.svg")

      el-table-column(width="15")
        // space

    dialog-add-sticker(
      v-if="dialog"
      :loading="loading"
      v-model="dialog"
      @save-item="saveItem"
      @close-dialog="dialog = false"
    )
</template>

<script>

import { reactive, onMounted, defineComponent } from 'vue';
import VueMultiselect from 'vue-multiselect';
import DialogAddSticker from './DialogAddSticker.vue';
import shared from '../shared';
import manageContentMediaShared from '../../manage-content/media/shared';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'ManageContent_Media_Stickers2',
  components: { DialogAddSticker, VueMultiselect },
  setup() {
    const {
      route,
      ref,
      getOrgStickers,
      updateOrgSticker,
      doGetStripeAuthorizeUrl,
      loading,
      getOrganization,
      router,
    } = shared();
    const store = useStore();


    const { saveStickerStatusBulk } = manageContentMediaShared();
    const items = reactive({ items: [] });
    const { orgId } = route.params;
    const stripeOnboarded = ref(false);
    const dialog = ref(false);

    function redirectToCreateSticker() {
      router.push(`/manage-content/media/add-stickers-v2`);
    }
    const allItems = reactive({ items: [] });
    const currentView = ref('ALL');
    const selected = ref([]);
    const textFilter = ref('');
    const isFiltered = ref(false);
    const sortOptions = [{ label: 'Title', value: 'title' }, { label: 'Cost', value: 'cost' }, { label: 'Creation Date', value: 'created' }];
    const sortBy = ref('');

    function strToDate(str) {
      return new Date(str).getTime();
    }

    async function getOrganizationDetails() {
      const organization = await getOrganization(orgId);
      // console.log('organization is ', organization.stripe_onboarded);
      stripeOnboarded.value = organization.stripe_onboarded;
    }

    async function getItems() {
      try {
        loading.value = true;
        items.items = await getOrgStickers(orgId);
        // set the default order to be descending
        items.items = items.items.sort((a, b) => strToDate(b.createdAt) - strToDate(a.createdAt));
        allItems.items = items.items;
      } catch (err) {
        console.log(err);
      } finally {
        loading.value = false;
        // console.log('items.items are ', items.items);
      }
    }

    async function saveItem(input) {
      await updateOrgSticker(orgId, input);
      await getItems();
    }

    function stickerState(itm) {
      let statusText = '';
      statusText = itm.published ? 'Approved' : 'Pending';
      if (itm.status === 'Archived') {
        statusText = 'Archived';
      }
      return statusText;
    }

    async function onBoardOnStripe() {
      console.log('----------------------------------');
      const stripeLink = await doGetStripeAuthorizeUrl(orgId);
      console.log(stripeLink);
      window.location.href = stripeLink;
      console.log('----------------------------------');
    }

    function filter2() {
      if (textFilter.value === '') {
        isFiltered.value = false;
        return allItems.items;
      }
      const regex = new RegExp(`${textFilter.value}`, 'gi');
      items.items = allItems.items.filter((x) => {
        if (x.title.match(regex) || x.description.match(regex)) {
          isFiltered.value = true;
          return true;
        }
        return false;
      });
      return false;
    }

    function sortTable() {
      if (sortBy.value.value === 'title') {
        // eslint-disable-next-line max-len
        items.items = items.items.sort((a, b) => a[sortBy.value.value].localeCompare(b[sortBy.value.value]));
      } else if (sortBy.value.value === 'created') {
        // eslint-disable-next-line max-len
        items.items = items.items.sort((a, b) => strToDate(a.createdAt) - strToDate(b.createdAt));
      } else {
        items.items = items.items.sort((a, b) => a.cost - b.cost);
      }
    }

    function onCheckboxChange(checkedItems) {
      selected.value = checkedItems;
    }

    function getClass(currentItem) {
      if (currentItem === currentView.value) {
        return 'filter-active';
      }
      return 'filter-inactive';
    }

    function getButtonClass(currentItem) {
      switch (currentItem) {
        case 'Pending':
          return 'pending-button';
        case 'Not Approved':
          return 'notapproved-button';
        default:
          return 'approved-button';
      }
    }

    function clearTextFilter() {
      textFilter.value = '';
      isFiltered.value = false;
      items.items = allItems.items;
    }
    function headerFilter(filterBy) {
      currentView.value = filterBy;
      if (filterBy === 'pending') {
      // eslint-disable-next-line array-callback-return, max-len
        items.items = allItems.items.filter((item) => (item.published === false && item.status != 'Archived'));
      } else if (filterBy === 'approved') {
      // eslint-disable-next-line array-callback-return
        items.items = allItems.items.filter((item) => item.published === true);
      } else if (filterBy === 'notApproved') {
      // eslint-disable-next-line array-callback-return, max-len
        items.items = allItems.items.filter((item) => (item.seen === true && item.published === false && item.status == 'Archived'));
      } else {
        items.items = allItems.items;
      }
    }

    async function bulkUpdate(type) {
      const bulkStickers = [];
      selected.value.forEach((element) => {
        const item = { id: element.id, status: type };
        bulkStickers.push(item);
      });
      await saveStickerStatusBulk(bulkStickers);
      await getItems();
    }

    onMounted(async () => {
      await store.dispatch('sticker/RESET_TO_DEFAULT');
      await getItems();
      await getOrganizationDetails();
    });

    return {
      items,
      loading,
      selected,
      textFilter,
      isFiltered,
      currentView,
      sortOptions,
      sortBy,
      stripeOnboarded,
      orgId,
      dialog,
      // methods
      saveItem,
      stickerState,
      onBoardOnStripe,
      getClass,
      getButtonClass,
      headerFilter,
      onCheckboxChange,
      bulkUpdate,
      filter2,
      sortTable,
      clearTextFilter,
      redirectToCreateSticker,
    };
  },
});
</script>
