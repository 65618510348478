<script setup lang="ts">
import {ref, defineProps, computed, watch, onMounted, defineEmits} from 'vue';
import VueMultiselect from 'vue-multiselect';
import GlowPicker from "@/components/StickerTIcket/GlowPicker.vue";
import store from "@/store";

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '../../../../../assets/styles/qill.scss';
import {combineDateAndTime} from "@/shared/sticker-date-combos";
import {ElTimePicker} from "element-plus";
import {timezones} from "@/data/timezones";
import moment from 'moment-timezone';

const emit = defineEmits(['onFetchCauses']);

const allowNamingPrices = ref(false);

const dateStart = ref();
const timeStart = ref();
const dateEnd = ref();
const timeEnd = ref();
const dateTimeTimezone = ref();

const props = defineProps<{
  causes: string[];
  availableTags: string[];
  causeLoading: boolean;
}>()

const item = computed({
  get: () => store.getters['sticker/GET_STICKER'],
  set: (value) => {
    store.dispatch('sticker/SET_STICKER', value);
  }
})

const combineTime = () => {
  const startDate = combineDateAndTime(dateStart.value, timeStart.value);
  const endDate = combineDateAndTime(dateEnd.value, timeEnd.value);
  const targetTimeZone = dateTimeTimezone.value;

  const convertToUTC = (date, timeZone) => {
    const localDate = moment.tz(date, timeZone);
    return localDate.utc().format();
  };

  if (targetTimeZone != null) {
    const startDateInUTC = convertToUTC(startDate, targetTimeZone);
    const endDateInUTC = convertToUTC(endDate, targetTimeZone);

    store.dispatch("sticker/SET_STICKER", {
      startDate: startDateInUTC,
      endDate: endDateInUTC,
      startDateTimeZone: targetTimeZone,
      endDateTimeZone: targetTimeZone,
    });
  }
};

watch([dateStart, timeStart, dateEnd, timeEnd, dateTimeTimezone], () => {
  combineTime()
})

onMounted(async () => {

  if (item?.value?.quickCosts?.length) {
    for (let qc of item.value.quickCosts) {
      if (qc !== null) {
        allowNamingPrices.value = true;
      }
    }
  }

  const receivedStartDate = item.value.startDate;
  const timezone = item.value.startDateTimeZone;

  dateTimeTimezone.value = timezone;

  const userTimezone = moment.tz.guess();

  const start = moment.tz(receivedStartDate, 'YYYY-MM-DDTHH:mm:ssZ', userTimezone)
    .clone()
    .tz(timezone);

  if (start && typeof start.format === 'function') {
    dateStart.value = start.format('YYYY-MM-DD');
    timeStart.value = start.format('hh:mm A');
  }

  const receivedEndDate = item.value.endDate;
  const end = moment.tz(receivedEndDate, 'YYYY-MM-DDTHH:mm:ssZ', userTimezone)
      .clone()
      .tz(timezone);

  if (end && typeof start.format === 'function') {
    dateEnd.value = end.format('YYYY-MM-DD');
    timeEnd.value = end.format('hh:mm A');
  }
  
});

const preventArrowKeys = (event: KeyboardEvent) => {
  if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
    event.preventDefault();
  }
};

</script>

<template>
  <section class="c-campaign-sticker">
    <article class="c-campaign-sticker__row c-campaign-sticker__row--column">
      <span class="c-campaign-sticker__label">Sticker Title*</span>
      <input class="c-input" type="text" required placeholder="Title" v-model="item.title" />
    </article>

    <article class="c-campaign-sticker__row c-campaign-sticker__row--tags-cost">
      <div class="c-campaign-sticker__row c-campaign-sticker__row--column c-campaign-sticker__row--tags">
        <span class="c-campaign-sticker__label">Tags*</span>
        <vue-multiselect
          class="c-input-multiselect"
          v-model="item.tags"
          :multiple="true"
          :options="availableTags"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          placeholder="Tag"
        />
      </div>
      <div class="c-campaign-sticker__row c-campaign-sticker__row--column">
        <span class="c-campaign-sticker__label">Cost</span>
        <input class="c-input" type="text" required placeholder="Cost" v-model="item.cost" />
      </div>
    </article>

    <article class="c-quick-costs">
      <el-checkbox v-model="allowNamingPrices">Allow user's to name price</el-checkbox>

      <div v-if="allowNamingPrices" class="c-quick-costs__container">
        <p class="c-quick-costs__title">Quick cost options</p>
        <div class="c-quick-costs__costs">
          <section class="position-relative" v-for="(cost, index) in 4">
            <div class="c-input-prefix" v-show="item.quickCosts[index]">$</div>
            <input
              class="c-input"
              :key="cost"
              v-model.number="item.quickCosts[index]"
              size="small"
              :placeholder="'Cost ' + (cost)"
              type="number"
              step="1"
              min="0"
              @keydown="preventArrowKeys"
              @wheel.prevent
            />
          </section>
        </div>
      </div>
    </article>

    <article class="c-campaign-sticker__row c-campaign-sticker__row--column">
      <span class="c-campaign-sticker__label">Details*</span>
      <QuillEditor :toolbar="['bold', 'italic', 'underline', 'strike']" placeholder="" v-model:content="item.description" content-type="html" />
    </article>

    <article class="c-campaign-sticker__row c-campaign-sticker__row--column c-campaign-sticker__row--causes">
        <span class="c-campaign-sticker__label">Non-Profit Suggestions*</span>
        <vue-multiselect
            class="c-input-multiselect"
            :multiple="true"
            :options="causes"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            placeholder="Non profit"
            v-model="item.causes"
            :loading="causeLoading"
            @search-change="(val) => emit('onFetchCauses', val)"
        />
    </article>

    <article class="c-campaign-sticker__row c-campaign-sticker__row--column">
        <span class="c-campaign-sticker__label">Max Quantity (optional)</span>
        <input class="c-input" type="number" v-model="item.maxQuantity" @keydown="preventArrowKeys" @wheel.prevent/>
    </article>


    <article class="c-campaign-sticker__row c-campaign-sticker__row--column">
        <span class="c-campaign-sticker__label">Start Date & Time*</span>
        <article class="c-glow-picker__group">
          <div class="c-glow-picker__group__inner">
            <el-date-picker class="c-glow-picker__input" v-model="dateStart" format="MM/DD/YY" />
            <div class="position-relative">
              <el-time-picker
                arrow-control
                class="c-custom-time"
                format="hh:mm A"
                v-model="timeStart"
                value-format="hh:mm A"
              />
            </div>
          </div>
        </article>
    </article>

    <article class="c-campaign-sticker__row c-campaign-sticker__row--column">
        <span class="c-campaign-sticker__label">End Date & Time*</span>
        <article class="c-glow-picker__group">
          <div class="c-glow-picker__group__inner">
            <el-date-picker class="c-glow-picker__input" v-model="dateEnd" format="MM/DD/YY" />
            <div class="position-relative">
              <el-time-picker
                arrow-control
                class="c-custom-time"
                format="hh:mm A"
                v-model="timeEnd"
                value-format="hh:mm A"
              />
            </div>
          </div>
        </article>
    </article>

    <el-select class="c-glow-picker__input" placeholder="Timezone" v-model="dateTimeTimezone">
      <el-option
        v-for="timezone in timezones"
        :key="timezone.value"
        :label="timezone.label"
        :value="timezone.value">
      </el-option>
    </el-select>

    <glow-picker />
  </section>

</template>

<style lang="scss">
.c-campaign-sticker {
  display: flex;
  flex-direction: column;
  gap: 30px;
  min-width: 340px;

  .el-select {
    width: 100%;
    max-width: 340px;

    &__placeholder {
      color: #000000;
      font-family: "Red Hat Display";
      font-size: 14px;
      font-weight: 500;

      &.is-transparent {
        color: #606266;
        font-size: 0.875rem;
        font-weight: 400;
      }
    }

    &__wrapper {
      height: 100%;
      border: none;
      box-shadow: none;
      width: 338px;
    }
  }

  &__label {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
  }

  &__row {
    display: flex;

    &--tags {
      .multiselect {
        max-width: 160px;
      }
    }

    &--causes {
      .multiselect {
        max-width: 340px;
      }
    }

    &--column {
      flex-direction: column;
    }

    & {
      position: relative;
    }

    &--tags-cost {
      gap: 20px;
      > div {
        width: calc(50% - 10px);
      }
    }
  }

  /* Hide the arrows in number input fields */
  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield; /* Firefox */
    appearance: textfield; /* Standard property for compatibility */
  }
}
</style>
